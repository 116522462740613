import { Alert, Button, Divider, FormControl, IconButton, MenuItem, Select, Stack, TextField, } from "@mui/material";
import CustomizedModal from ".";
import styles from "./applyNowModal.module.scss";
import pxToRem from "../../helpers/pxToRem";
import { memo, useEffect, useState } from "react";
import { ROUTE_PARAMS } from "../../routers/shared";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import useTextInput from "../../hooks/useTextInput";
import { EMPTY_INPUT_HELPER_MSG, getErrorMsgForEmailInput, getErrorMsgForMobileInput, mobileNoValidator, } from "../../helpers/inputValidators";
import useGetScreenType from "../../hooks/useGetScreenType";
import axios from "axios";
import { PUBLICKEY } from "../../helpers/constants";
import JSEncrypt from "jsencrypt";
import ReCAPTCHA from "react-google-recaptcha";

type TApplyNowModal = {
    isApplyNowModal: boolean;
    handleClose: () => void;
};
  
const ApplyNowModal = ({ isApplyNowModal, handleClose }: TApplyNowModal) => {
    const siteKey = "6LfhICApAAAAAJ5wzy_x6wl37i09BHfM_yAvqH-7";
    const screenType = useGetScreenType();
    const carTypes = [ { value: 'Small', label: 'Small' }, { value: 'Family', label: 'Family' }, { value: 'Offroad 4x4', label: 'Offroad 4x4' }, { value: 'Prestige', label: 'Prestige' }, { value: 'Tradie', label: 'Tradie' } ];
    const navigate = useNavigate();
    const carType = useTextInput({
        inputValidator: (input) => {
        if (input.length === 0) {
            return EMPTY_INPUT_HELPER_MSG;
        }
        },
        cancelHasTouchedAutoUpdate: true,
    });
    const firstName = useTextInput({
        inputValidator: (input) => {
        if (input.length === 0) {
            return EMPTY_INPUT_HELPER_MSG;
        }
        },
        cancelHasTouchedAutoUpdate: true,
    });
    const lastName = useTextInput({
        inputValidator: (input) => {
        if (input.length === 0) {
            return EMPTY_INPUT_HELPER_MSG;
        }
        },
        cancelHasTouchedAutoUpdate: true,
    });
    const mobile = useTextInput({
        inputValidator: (input) => mobileNoValidator(input),
        cancelHasTouchedAutoUpdate: true,
    });
    const email = useTextInput({
        inputValidator: (input) => getErrorMsgForEmailInput(input),
        cancelHasTouchedAutoUpdate: true,
    });
    const postcode = useTextInput({
        inputValidator: (input) => {
        if (input.length === 0) {
            return EMPTY_INPUT_HELPER_MSG;
        }
        },
        cancelHasTouchedAutoUpdate: true,
    });
    const [accidentYourFault, setAccidentYourFault] = useState< "No" | "Yes" | "Not Sure" >("No");
    const [errMsg, setErrMsg] = useState("");
    const [isFinished, setIsFinished] = useState(false);
    const [isSendingSMS, setIsSendingSMS] = useState(false);
    const [token, setToken] = useState<string | null>(null);

    const clearForm = () => {
        carType.setValue("");
        firstName.setValue("");
        lastName.setValue("");
        mobile.setValue("");
        email.setValue("");
        postcode.setValue("");
        setToken(null);
    };
    const sendSMS = async () => {
        setIsSendingSMS(true);
        try {
        const mobileNo =
            "+61" +
            mobile.value.toString().substring(1, mobile.value.toString().length);
        const data = {
            firstName: firstName.value.toString(),
            lastName: lastName.value.toString(),
            carType: carType.value.toString(),
            email: email.value.toString(),
            accidentYourFault: accidentYourFault,
            postCode: postcode.value.toString(),
            mobileNo: mobileNo,
        };
        const encryptor = new JSEncrypt();
        encryptor.setPublicKey(PUBLICKEY);
        const encrypted = encryptor.encrypt(JSON.stringify(data));
        if (encrypted) {
            const response = await fetch("https://coveryou.claims/essendex.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: process.env.REACT_APP_API_KEY ?? "",
            },
            body: JSON.stringify({ encryptedData: encrypted, captcha: token }),
            });
            const data = await response.json();
            if (data.status === 200) {
            handleClose();
            setIsFinished(true);
            } else {
            handleClose();
            setErrMsg(response.statusText);
            }
            setIsSendingSMS(false);
        } else {
            setErrMsg("Error: Something goes wrong, please try again.");
            setIsSendingSMS(false);
        }
        } catch (error) {
        setIsSendingSMS(false);
        }
    };

    const onSubmit = async () => {
        if (!token) {
        setErrMsg(
            "Verification failed. Please try again or contact support if the issue persists."
        );
        return;
        }

        carType.setHasTouched(true);
        firstName.setHasTouched(true);
        lastName.setHasTouched(true);
        mobile.setHasTouched(true);
        email.setHasTouched(true);
        postcode.setHasTouched(true);
        if (
        carType.hasError ||
        firstName.hasError ||
        lastName.hasError ||
        mobile.hasError ||
        email.hasError ||
        postcode.hasError ||
        !accidentYourFault
        )
        return;
        sendSMS();
    };

    const onChange = (captcha: string | null) => {
        if (!captcha) {
        setToken(null);
        return;
        }
        setToken(captcha);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [isApplyNowModal]);

    return (
        <>
            <CustomizedModal
                open={errMsg ? true : false}
                handleClose={() => setErrMsg("")}
            >
                <Stack direction={"column"}>
                <Stack style={{ marginTop: 16, fontSize: 30, color: "red" }}>
                    Error
                </Stack>
                <Divider
                    style={{ marginTop: pxToRem(20), marginBottom: pxToRem(20) }}
                />
                <Stack>
                    <label style={{ color: "red" }}>{errMsg}</label>
                </Stack>
                <Divider
                    style={{ marginTop: pxToRem(20), marginBottom: pxToRem(20) }}
                />
                <Stack direction={"row-reverse"}>
                    <Button
                    style={{ width: pxToRem(100) }}
                    onClick={() => setErrMsg("")}
                    className={styles.submitButton}
                    >
                    Close
                    </Button>
                </Stack>
                </Stack>
            </CustomizedModal>
            <CustomizedModal
                open={isApplyNowModal}
                handleClose={() => {
                handleClose();
                clearForm();
                }}
                ovstyle={{ height: pxToRem(740) }}
            >
                <Stack>
                <Stack style={{ marginTop: 16 }} className={styles.modalHeader}>
                    {" "}
                    <IconButton
                    className={styles.modalHeaderClose}
                    onClick={handleClose}
                    >
                    {" "}
                    <CloseIcon className={styles.closeIcon} />{" "}
                    </IconButton>{" "}
                </Stack>
                <Stack className={styles.modalContainer}>
                    <h2>Start your</h2>
                    <h2>reservation</h2>
                    <big>Reserve your car. No obligations. No commitments.</big>
                    <FormControl fullWidth className={styles.form}>
                    <Stack spacing={2}>
                        <Stack>
                        <big>What Car type would you like to reserve?</big>
                        <Select
                            value={carType.value}
                            onChange={(e) => carType.setValue(e.target.value)}
                        >
                            <MenuItem value=''>
                            {" "}
                            <em>Select One</em>{" "}
                            </MenuItem>
                            {carTypes.map((carType) => (
                            <MenuItem key={carType.value} value={carType.value}>
                                {" "}
                                {carType.label}{" "}
                            </MenuItem>
                            ))}
                        </Select>
                        {carType.hasError ? (
                            <span
                            style={{
                                color: "red",
                                fontSize: "0.75rem",
                                fontWeight: 400,
                                fontFamily: "Poppins",
                                marginTop: pxToRem(3),
                            }}
                            >
                            {carType.getErrorMessage()}
                            </span>
                        ) : null}
                        </Stack>
                        <Stack
                        direction={screenType === "PC" ? "row" : "column"}
                        spacing={2}
                        >
                        <Stack className={styles.formControl}>
                            <big>First Name</big>
                            <TextField
                            value={firstName.value}
                            onChange={(e) => firstName.setValue(e.target.value)}
                            helperText={firstName.getErrorMessage()}
                            error={firstName.hasError && firstName.hasTouched}
                            className={styles.textField}
                            variant='outlined'
                            />
                        </Stack>
                        <Stack className={styles.formControl}>
                            <big>Last Name</big>
                            <TextField
                            value={lastName.value}
                            onChange={(e) => lastName.setValue(e.target.value)}
                            helperText={lastName.getErrorMessage()}
                            error={lastName.hasError && lastName.hasTouched}
                            className={styles.textField}
                            variant='outlined'
                            />
                        </Stack>
                        </Stack>
                        <Stack
                        direction={screenType === "PC" ? "row" : "column"}
                        spacing={2}
                        >
                        <Stack className={styles.formControl}>
                            <big>Mobile No #</big>
                            <TextField
                            placeholder='eg. 0412345678'
                            value={mobile.value}
                            onChange={(e) => mobile.setValue(e.target.value)}
                            helperText={mobile.getErrorMessage()}
                            error={mobile.hasError && mobile.hasTouched}
                            className={styles.textField}
                            variant='outlined'
                            />
                        </Stack>
                        <Stack className={styles.formControl}>
                            <big>Email</big>
                            <TextField
                            value={email.value}
                            onChange={(e) => email.setValue(e.target.value)}
                            helperText={email.getErrorMessage()}
                            error={email.hasError && email.hasTouched}
                            className={styles.textField}
                            variant='outlined'
                            />
                        </Stack>
                        </Stack>
                        <Stack
                        direction={screenType === "PC" ? "row" : "column"}
                        spacing={2}
                        >
                        <Stack className={styles.formControl}>
                            <big>Accident your fault?</big>
                            <Stack direction={"row"} spacing={2}>
                            <Stack
                                onClick={() => setAccidentYourFault("No")}
                                className={`${styles.switchbutton} ${
                                accidentYourFault === "No"
                                    ? styles.switchbuttonactive
                                    : ""
                                }`}
                            >
                                <label>No</label>
                            </Stack>
                            <Stack
                                onClick={() => setAccidentYourFault("Yes")}
                                className={`${styles.switchbutton} ${
                                accidentYourFault === "Yes"
                                    ? styles.switchbuttonactive
                                    : ""
                                }`}
                            >
                                <label>Yes</label>
                            </Stack>
                            <Stack
                                onClick={() => setAccidentYourFault("Not Sure")}
                                className={`${styles.switchbutton} ${
                                accidentYourFault === "Not Sure"
                                    ? styles.switchbuttonactive
                                    : ""
                                }`}
                            >
                                <label style={{ lineHeight: 1 }}>Not Sure</label>
                            </Stack>
                            </Stack>
                        </Stack>
                        <Stack className={styles.formControl}>
                            <big>Postcode</big>
                            <TextField
                            value={postcode.value}
                            onChange={(e) => postcode.setValue(e.target.value)}
                            helperText={postcode.getErrorMessage()}
                            error={postcode.hasError && postcode.hasTouched}
                            className={styles.textField}
                            variant='outlined'
                            />
                        </Stack>
                        </Stack>
                        <Stack>
                        <small>
                            By tapping "Submit," you agree to our{" "}
                            <a
                            href={`/${ROUTE_PARAMS.TERMS_OF_USE}`}
                            className={styles.link}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(`/${ROUTE_PARAMS.TERMS_OF_USE}`);
                            }}
                            >
                            Terms of Service
                            </a>
                            , and{" "}
                            <a
                            href={`/${ROUTE_PARAMS.PRIVACY_POLICIES}`}
                            className={styles.link}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(`/${ROUTE_PARAMS.PRIVACY_POLICIES}`);
                            }}
                            >
                            Privacy Policy{" "}
                            </a>
                            . You also agree to be contacted about Coveryou products,
                            including through autodialed calls or texts. This is not a
                            condition of hire Eligibility check is required prior to
                            hire booking. See our Privacy Policy for how we handle your
                            data.
                        </small>
                        </Stack>
                        <ReCAPTCHA sitekey={siteKey} onChange={onChange} />
                        <Stack>
                        <Button
                            disabled={isSendingSMS}
                            onClick={() => onSubmit()}
                            className={styles.submitButton}
                        >
                            {isSendingSMS ? "Submitting..." : "Submit"}
                        </Button>
                        </Stack>
                    </Stack>
                    </FormControl>
                </Stack>
                </Stack>
            </CustomizedModal>
            <CustomizedModal
                open={isFinished}
                handleClose={() => {
                setIsFinished(false);
                clearForm();
                }}
            >
                <Stack>
                <Stack style={{ marginTop: 16 }} className={styles.modalHeader}>
                    {" "}
                    <IconButton
                    className={styles.modalHeaderClose}
                    onClick={() => setIsFinished(false)}
                    >
                    {" "}
                    <CloseIcon className={styles.closeIcon} />{" "}
                    </IconButton>{" "}
                </Stack>
                <Stack className={styles.modalContainer}>
                    <h2>Reservation</h2>
                    <h2>Pending</h2>
                    <big style={{ marginTop: pxToRem(10) }}>
                    Thank you for submitting your information. Yoy are one step closer
                    to getting a hire car at no cost to you.
                    </big>
                    <Divider
                    style={{ marginTop: pxToRem(20), marginBottom: pxToRem(20) }}
                    />
                    <h2 style={{ fontWeight: 400 }}>Check your texts</h2>
                    <big>
                    <b>We've sent you a link to our application form.</b>
                    </big>
                    <big>Didn't receive it? Tap below to resend it.</big>
                </Stack>
                <Stack
                    style={{
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: pxToRem(20),
                    }}
                >
                    <ReCAPTCHA sitekey={siteKey} onChange={onChange} />
                    <Button
                    disabled={isSendingSMS}
                    style={{ width: pxToRem(300) }}
                    onClick={() => sendSMS()}
                    className={styles.submitButton}
                    >
                    {isSendingSMS ? "Resendng Text..." : "Resend Text"}
                    </Button>
                </Stack>
                </Stack>
            </CustomizedModal>
        </>
    );
};
  
export default memo(ApplyNowModal);