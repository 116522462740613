import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Stack from "@mui/material/Stack";
import styles from "./FifthContent.module.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LANDING_PAGE_PARAMS, ROUTE_PARAMS } from "../../../routers/shared";
import pxToRem from "../../../helpers/pxToRem";

// TODO: DES
const CONFIGS: { title: string; des: JSX.Element | string }[] = [
  {
    title: "How much does the service cost?",
    des: "There are no upfront costs for our service. If you are eligible for the replacement car, Coveryou will include fuel, insurance, maintenance, servicing and 24/7 roadside assist. Some fees do apply. For example tolls, infringements and insurance excess fees apply.",
  },
  {
    title: "How do I pick up the car and return it?",
    des: "Coveryou will send you an Uber voucher to cover the transportation costs for picking up the car and returning it to its base. You will not incur any out of pocket Uber expenses.",
  },
  {
    title: "What hire vehicle can I Choose?",
    des: "We offer a variety of vehicles to match your lifestyle, including hatchbacks, SUVs, 7- seaters, utes, and vans.",
  },
  {
    title: "Can I easily get a Coveryou replacement vehicle after an accident?",
    des: "Once your booking is approved, you will be able to access a car. Coveryou will help you create a profile to the digital key app on a supported mobile device. Once downloaded, you will need to create a password for your account and complete your booking by uploading photos of your license and adding your debit/credit card details. Don't worry, the credit card will be securely stored and will be used for tolls and excess charges only.",
  },
  {
    title: "Who is responsible for the cost of the replacement vehicle?",
    des: (
      <big>
        As per <a href={`/${ROUTE_PARAMS.TERMS_OF_USE}`}>our agreement*</a> , you will not be responsible for
        the hire costs if you have received prior approval, assist in getting
        reimbursement of your expenses from the insurer, and comply with our
        terms and conditions. However, if we need your help in recovering the
        hire costs, you may be asked to provide evidence, witness statements, or
        even attend court in rare cases. Please note that you will still be
        responsible for paying for petrol, tolls, and any infringements, just as
        you would if you were driving your own car."
      </big>
    ),
  },
];

const FifthContent = () => (
  <Stack
    id={LANDING_PAGE_PARAMS.FAQ}
    className={`${styles.FifthContent} fillScreen`} style={{ backgroundColor: 'white' }}
  >
    <div style={{ padding: pxToRem(50), backgroundColor:'#ebf4f6', alignItems:'center', justifyContent:'center'}}>
        <h1 style={{ textAlign:'center', marginBottom: pxToRem(10) }}>FAQ</h1>
        <p className={styles.desText}>
        Have a question? View responses to commonly asked questions below. If you still have a query, don’t hesitet to contact us at{" "}
            <a href='mailto:help@coveryou.com.au' className='link'>
                help@coveryou.com.au
            </a>
        </p>
    </div>
    <Stack className={styles.accordion}>
      {CONFIGS.map((config) => (
        <Accordion key={config.title} style={{ width: '100%'}} className={styles.accordincontent} elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel2a-content' id='panel2a-header' >
            <small className='thin'>{config.title}</small>
          </AccordionSummary>
          <AccordionDetails>
            {typeof config.des === "string" ? ( <big>{config.des}</big> ) : ( config.des )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  </Stack>
);

export default FifthContent;
