export const URL_PARAMS = {};

export const ROUTE_PARAMS = {
  PRIVACY_POLICIES: "privacy",
  TERMS_OF_USE: "terms-of-use",
  CONTACT:'contact-us',
};

export interface RouteStore {
  [routeName: string]: JSX.Element;
}

export const LANDING_PAGE_PARAMS = {
  HOW_IT_WORKS: "how-it-works",
  FAQ: "faq",
  CONTACT: "contact",
  //FOR_ENTERPRISE: "for-enterprise",
  APP: "app",
  ALLCOVER: "all-cover",
  FOR_INSURERS_REPAIRS: "for-insurers-repairs",
};

export const COVER_YOU_ADDRESS = "https://coveryou.com.au/";

export const SEO_CONFIGS = {
  [`/${LANDING_PAGE_PARAMS.HOW_IT_WORKS}`]: {
    title: "How it works",
    des: "With just a few simple steps, you can reserve your hire car and be back on the road in no time.",
  },
  [`/${LANDING_PAGE_PARAMS.FAQ}`]: {
    title: "Understand our services and how we can assist you",
    des: "Have questions about our services, and we're here to help. That's why we've created a comprehensive FAQ page where you can find answers to some of the most commonly asked questions. ",
  },
  [`/${LANDING_PAGE_PARAMS.CONTACT}`]: {
    title: "Home",
    des: "If you've been involved in a car accident and need a hire car, we can help make the process as simple as possible for you. And the best part is that we offer our services at no cost to you.",
  },
  [`/${LANDING_PAGE_PARAMS.FOR_INSURERS_REPAIRS}`]: {
    title:
      "At Coveryou, we've designed our services with insurers and repairers in mind.",
    des: "Our offerings are uniquely integrated with Instant Keys technology, making the process as seamless as possible. When you choose to work with us, you can trust that there are no hidden costs. We also offer cost-effective hire car, supported by a full hybrid fleet. With Coveryou, you can have peace of mind knowing that we're dedicated to providing you and your customers with the best possible service.",
  },
  DEFAULT: {
    title: "Get a hire car at no cost to you ",
    des: "Not at Fault? You shouldn't have to pay for a replacement car. That's where our services come in. Our goal is to make sure that you have access to the transportation you need, without having to worry about the cost.",
  },
  [`/${ROUTE_PARAMS.PRIVACY_POLICIES}`]: {
    title: "Privacy policies",
  },
  [`/${ROUTE_PARAMS.TERMS_OF_USE}`]: {
    title: "Terms of use",
  },
};
