import styles from "./index.module.scss";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import "../../styles/fonts.scss";
import emailIcon from "../../assets/images/LandingPage/mail.png";
import phoneIcon from "../../assets/images/LandingPage/telephone.png";
import emailIcon2 from "../../assets/images/LandingPage/mail_2.png";
import phoneIcon2 from "../../assets/images/LandingPage/telephone_2.png";
import linkedin from "../../assets/images/LandingPage/linkedin.png";
import { IconButton} from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useEffect, useState } from "react";
import { COVER_YOU_ADDRESS, LANDING_PAGE_PARAMS, ROUTE_PARAMS } from "../../routers/shared";
import { useLocation, useNavigate } from "react-router-dom";
import pxToRem from "../../helpers/pxToRem";
import CloseIcon from "@mui/icons-material/Close";
import ApplyNowModal from "../CustomizedModal/applyNowModal";

const HEADER_BUTTONS = [
    { text: "How it works", sectionId: LANDING_PAGE_PARAMS.HOW_IT_WORKS },
    { text: "About us" },
    { text: "FAQ", sectionId: LANDING_PAGE_PARAMS.FAQ },
    { text: "Contact", sectionId: ROUTE_PARAMS.CONTACT },
    { text: "For Insurers & Repairs", sectionId: LANDING_PAGE_PARAMS.FOR_INSURERS_REPAIRS },
];

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [mobileHeaderVis, setMobileHeaderVis] = useState(false);
    const [isApplyNowButton, setIsApplyNowButton] = useState(false);
    const [isApplyNowModal, setIsApplyNowModal] = useState(false);
    
    useEffect(() => {
        if(location.pathname === "/" || location.pathname === "/how-it-works" || location.pathname === "/faq" || location.pathname === "/contact" || location.pathname === "/all-cover"){
            const handleScroll = () => {
                const y = window.scrollY;
                if(y >= 870){ setIsApplyNowButton(true); } //Approx Position of 2nd Content
                else { setIsApplyNowButton(false); }
            };
            window.addEventListener('scroll', handleScroll);
            return () => { window.removeEventListener('scroll', handleScroll); };
        }
    }, []);

    return (
        <Stack className={styles.header}>
            <ApplyNowModal isApplyNowModal={isApplyNowModal} handleClose={() => setIsApplyNowModal(false)} />
            <Stack className={styles.iconWrapper}>
                <img src={require('../../assets/images/logo.png')} alt='cover you icon' className={styles.icon} onClick={() => navigate("/")} />
            </Stack>
            <Stack className={styles.buttonsStack}>
                {/* TODO: NAVIGATION */}
                {HEADER_BUTTONS.map((config) => (
                    <a key={config.text} className={styles.textButton} href={`${config.sectionId ?? COVER_YOU_ADDRESS}`} 
                    onClick={(e) => { 
                        e.preventDefault(); 
                        if (config.sectionId) { 
                            navigate(`/${config.sectionId}`); 
                            if(config.sectionId === LANDING_PAGE_PARAMS.CONTACT){ setIsApplyNowModal(true); }
                        } 
                        else { 
                            window.open(COVER_YOU_ADDRESS, "_self"); 
                        } 
                    }} > {config.text} </a>
                ))}
                <Stack className={styles.contactButtonsStack} direction={"row"} spacing={2}>
                    <a href='tel:+611300505508'> <img style={{width: pxToRem(30)}} src={phoneIcon} alt='phone icon' className={styles.phoneButton} /> </a>
                    { isApplyNowButton?<Button onClick={() => setIsApplyNowModal(true)} className={styles.applyNowButton}>Apply Now</Button>:<a href='mailto:help@coveryou.com.au'> <img style={{width: pxToRem(30)}} src={emailIcon} alt='email icon' className={styles.imgButton} /> </a> }
                </Stack>
            </Stack>
            <IconButton className={styles.menuButton} onClick={() => setMobileHeaderVis((preState) => !preState)} >
                <MenuOutlinedIcon />
            </IconButton>
            <Stack className={styles.mobileHeaderMenu} sx={{ display: mobileHeaderVis ? "flex" : "none" }} onClick={() => setMobileHeaderVis((preState) => !preState)} >
                <Stack className={styles.mobileHeaderMenuCloseButtonStack}>
                    <IconButton>
                        <CloseIcon className={styles.closeIcon} />
                    </IconButton>
                </Stack>
                <Stack className={styles.mobileHeaderMenuButtonsStack}>
                    {HEADER_BUTTONS.map((config) => ( 
                        <a key={config.text} 
                            href={`${config.sectionId ?? COVER_YOU_ADDRESS}`} 
                            className={styles.mobileTextButton} 
                            onClick={(e) =>  {
                                e.preventDefault();
                                navigate(`/${config.sectionId}`);
                                if(config.sectionId === LANDING_PAGE_PARAMS.CONTACT){ setIsApplyNowModal(true); }
                            }}
                            style={{ textDecoration: "none" }} > {config.text} </a> 
                    ))}
                    <Stack direction={"row"} style={{ alignItems:'center', justifyContent:'start', marginLeft: pxToRem(-8) }}>
                        <a href='mailto:help@coveryou.com.au'>
                            <IconButton> 
                                <img style={{ width: pxToRem(38)}} src={emailIcon2} alt='Email Us' className={styles.iconButton} />
                            </IconButton>
                        </a>
                        <a href='https://www.linkedin.com/company/coveryou-services'>
                            <IconButton> 
                                <img style={{ width: pxToRem(32)}} src={linkedin} alt='Linkedin' className={styles.iconButton} />
                            </IconButton>
                        </a>    
                    </Stack>
                    
                    <a href='tel:+611300505508'>
                        <IconButton>
                            <img style={{ width: pxToRem(24)}} src={phoneIcon2} alt='Phone Number' className={styles.iconButton} />
                            <label style={{color:'white', fontSize: "1.2rem", marginLeft: pxToRem(2)}}>1300 505 508</label>
                        </IconButton>
                    </a>
                    { 
                        isApplyNowButton?
                            <Button onClick={() => setIsApplyNowModal(true)} className={styles.applyNowButton}>Apply Now</Button> 
                        :
                            null
                        }
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Header;
