import Stack from "@mui/material/Stack";
import styles from "./SecondContent.module.scss";
import nocosttoyou from "../../../assets/images/LandingPage/nocosttoyou.png";
import unlimitedkm from "../../../assets/images/LandingPage/unlimitedkm.png";
import roadsidesssist from "../../../assets/images/LandingPage/roadsidesssist.png";
import deliveredanywhere from "../../../assets/images/LandingPage/deliveredanywhere.png";
import freecharging from "../../../assets/images/LandingPage/freecharging.png";
import Button from "@mui/material/Button";
import { COVER_YOU_ADDRESS, LANDING_PAGE_PARAMS, } from "../../../routers/shared";

const CONFIGS: ICard[] = [
  { imgUrl: nocosttoyou, title: "No Cost to you", subTitle: "* T&C apply" },
  { imgUrl: unlimitedkm, title: "Unlimited Km’s" },
  { imgUrl: roadsidesssist, title: "Roadside Assist" },
  { imgUrl: deliveredanywhere, title: "Delivered  Anywhere" },
  { imgUrl: freecharging, title: "Free Fuel"},
];

interface ICard {
  imgUrl: string;
  title: string;
  subTitle?: string;
}

const Card = ({ imgUrl, title, subTitle }: ICard) => (
  <Stack className={styles.card}>
    <img className={styles.icon} src={imgUrl} alt={title} />
    <h6 className={styles.cardTitle}>{title}</h6>
    {subTitle && <big className={styles.cardSubTitle}>{subTitle}</big>}
  </Stack>
);

const SecondContent = () => {
  return (
    <Stack className={`${styles.secondContent} fillScreen`} id={LANDING_PAGE_PARAMS.ALLCOVER}>
        <h1 className={styles.title}>All Coveryou insurance hires include:</h1>
        <Stack className={styles.iconsStack}>
            {CONFIGS.map((config) => ( <Card key={config.title} {...config} /> ))}
        </Stack>
        <Button variant='outlined' className={styles.Button} onClick={() => window.open(COVER_YOU_ADDRESS, "_self")} >
            <h5>About Coveryou</h5>
        </Button>
    </Stack>
  );
};

export default SecondContent;
