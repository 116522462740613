import Stack from "@mui/material/Stack";
import styles from "./ForthContent.module.scss";
import car1 from "../../../assets/images/LandingPage/car1.png";
import car2 from "../../../assets/images/LandingPage/car2.png";
import car3 from "../../../assets/images/LandingPage/car3.png";
import car4 from "../../../assets/images/LandingPage/car4.png";
import car5 from "../../../assets/images/LandingPage/car5.png";
import car6 from "../../../assets/images/LandingPage/car6.png";
import car7 from "../../../assets/images/LandingPage/car7.png";
import car8 from "../../../assets/images/LandingPage/car8.png";
import car9 from "../../../assets/images/LandingPage/car9.png";
import c4bg from "../../../assets/images/LandingPage/c4bg.png";
import Button from "@mui/material/Button";
import useGetScreenType from "../../../hooks/useGetScreenType";
import { LANDING_PAGE_PARAMS } from "../../../routers/shared";

const ForthContent = () => {
  const screenType = useGetScreenType();

  return (
    <Stack
      className={`fillScreen ${styles.forthContent}`}
      id={LANDING_PAGE_PARAMS.FOR_INSURERS_REPAIRS}
    >
      <Stack className={styles.iconsWrapper}>
        <img src={car1} alt='car1' className={styles.car} />
        <img src={car2} alt='car2' className={styles.car} />
        <img src={car3} alt='car3' className={styles.car} />
        <img src={car4} alt='car4' className={styles.car} />
        <img src={car5} alt='car5' className={styles.car} />
        <img src={car6} alt='car6' className={styles.car} />
        <img src={car7} alt='car7' className={styles.car} />
        <img src={car8} alt='car8' className={styles.car} />
        <img src={car9} alt='car9' className={styles.car} />
      </Stack>
      <Stack className={styles.textStack}>
        <h1>
          For Insurers and {screenType === "PC" && <br />}
          Repair companies
        </h1>
        <big>
          We believe in a sustainable, equitable future. One where drivers can
          freely enjoy the open road without destroying the planet. We’d like to
          help you reduce your carbon footprint by conveniently getting
          customers to drive into a greener future.
        </big>
        <div>
          {/* TODO: ACTION */}
          <a href='mailto:help@coveryou.com.au'>
            <Button variant='outlined' className={styles.Button}>
              <h5>Email us</h5>
            </Button>
          </a>
        </div>
      </Stack>
    </Stack>
  );
};

export default ForthContent;
